import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { getCandidateDetails } from 'api/candidate';

export function useGetCandidateData() {
  const { id: candidateId } = useParams();

  return useQuery({
    queryKey: ['getCandidateData', candidateId],
    queryFn: async () => {
      return await getCandidateDetails(candidateId);
    },
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });
}
