import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import useRequestHandler from 'api/RequestHandlerHook';
import hasPermission from 'utils/hasPermission';
import roles from 'constants/roles';
import { createCustomer, getCustomer, editCustomer } from 'api/customer';
import { Divider, Button, FormRow, FormGroup, Container, Span, Flexbox } from 'components/Shared/sharedStyle';
import { Input, FormLevelCheckbox } from 'components/Shared/sharedComponents';
import {
  customerFormNames,
  customerSchema,
  getCustomerSections,
  mapSaveCustometer,
  mapGetCustometerData,
  atLeastOneCheckboxSelected,
} from 'components/Customers/Customer/configCustomerLevel';
import useDispatchNotification from 'components/Shared/Notification/DispatchNotificationHook';
import { LevelColumns } from 'components/Customers/Customer/styleCustomer';
import { Loader } from 'components/Shared/Loader/Loaders';

const { FcompanyName } = customerFormNames;

const Customer = () => {
  const { dispatchErrorNotification } = useDispatchNotification();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isEditView = pathname.includes('edit-customer');
  const { id } = useParams();
  const customerSections = getCustomerSections();

  const requestHandler = useRequestHandler();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFirstTimeLoding, setIsFirstTimeLoding] = useState(true);
  const [numberOfLevels, setNumberOfLevels] = useState(1);

  const formFunctions = useForm({ validationSchema: customerSchema });

  const { register, reset, getValues, errors, handleSubmit } = formFunctions;

  const getCustomerData = useCallback(async () => {
    if (isEditView) {
      const res = await getCustomer(id);

      const mappedData = mapGetCustometerData(res);

      setNumberOfLevels(mappedData.numberOfLevels);
      reset(mappedData);
    }

    setIsFirstTimeLoding(false);
  }, [id, isEditView, reset]);

  useEffect(() => {
    getCustomerData();
  }, [getCustomerData]);

  const saveCustomer = async () => {
    setIsSubmitting(true);

    const formData = getValues({ nest: true });

    if (!atLeastOneCheckboxSelected(formData)) {
      dispatchErrorNotification({ errMsg: t('customer.mustSelectAtLeastOne') });
      return setIsSubmitting(false);
    }

    const mappedFormData = mapSaveCustometer(formData);

    await requestHandler({
      requestFunction: isEditView
        ? editCustomer({
            ...mappedFormData,
            id,
            numberOfLevels,
          })
        : createCustomer({ ...mappedFormData, numberOfLevels }),
      successMsg: t('notifications.saved'),
      gotoOnSuccess: !isEditView && '/customers',
    });

    getCustomerData();

    setIsSubmitting(false);
  };

  const isEditAllowed = hasPermission([roles.admin]);

  if (isFirstTimeLoding) return <Loader />;

  return (
    <Container>
      <fieldset disabled={isEditView && !isEditAllowed}>
        <h2>{pathname === '/add-customer' ? t('customer.addCustomer') : t('customer.editCustomer')}</h2>
        <Divider />
        <FormRow>
          <FormGroup>
            <Input
              label={t(`candidateForm.labels.${FcompanyName}`)}
              name={FcompanyName}
              register={register}
              error={errors[FcompanyName]}
            />
          </FormGroup>
          <FormGroup />
        </FormRow>
        <FormRow>
          <FormGroup>
            <LevelColumns>
              <Span>{t('customer.section')}</Span>
              {Array(numberOfLevels)
                .fill(0)
                .map((_, i) => (
                  <Flexbox key={i} direction="column" width="75px" gap="15px" align="center">
                    <Span>{`${t('customer.level')} ${i + 1}`}</Span>
                  </Flexbox>
                ))}
              <Button
                padding="7px 20px"
                disabled={isSubmitting}
                onClick={() => setNumberOfLevels((prevValue) => prevValue + 1)}
              >
                {t('customer.addLevel')}
              </Button>
            </LevelColumns>
          </FormGroup>
        </FormRow>
        {customerSections.map((section, index) => (
          <FormRow key={index}>
            <FormGroup>
              <FormLevelCheckbox
                label={section.label}
                name={section.name}
                formFunctions={formFunctions}
                numberOfLevels={numberOfLevels}
                isEditAllowed={isEditAllowed}
              />
            </FormGroup>
          </FormRow>
        ))}

        <Button disabled={isSubmitting} onClick={handleSubmit(saveCustomer)}>
          {t('common.save')}
        </Button>
      </fieldset>
    </Container>
  );
};

export default Customer;
