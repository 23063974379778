import store from 'store/store';
import { setCandidateSections } from 'store/actions';

const dispatch = store.dispatch;

const generalInfoFieldsNumber = 9;
const currentAdressFieldsNumber = 5;
const previousAdressFieldsNumber = 7;
const educationFieldsNumber = 13;
const workplacesFieldsNumber = 13;
const conflictOfInterestFieldsNumber = 2;

export const getPreviousAdressBaseNumber = (index) =>
  generalInfoFieldsNumber + currentAdressFieldsNumber + previousAdressFieldsNumber * index + 1;

const getAdditionalFieldsStartIndex = (numberOfPreviousAdresses) =>
  generalInfoFieldsNumber + currentAdressFieldsNumber + previousAdressFieldsNumber * numberOfPreviousAdresses + 1;

const getNumOfAdditionalFieldsShownInPreviousSections = (sections, sectionIndex) => {
  let numberOfFields = 0;
  for (let i = 1; i <= sectionIndex; i++) {
    numberOfFields += sections[i - 1].flat().length - 1;
  }
  return numberOfFields;
};

export const getAdditionalFieldLabel = ({
  numberOfPreviousAdresses,
  sectionNumber,
  sections,
  sectionIndex,
  fieldIndex,
  fieldName,
}) => {
  const startIndex = getAdditionalFieldsStartIndex(numberOfPreviousAdresses);
  const additionalFieldNumber =
    startIndex + getNumOfAdditionalFieldsShownInPreviousSections(sections, sectionIndex) + fieldIndex;

  return `${sectionNumber}.${additionalFieldNumber} ${fieldName}`;
};

export const getEducationBaseNumber = (index) => educationFieldsNumber * index;
export const getWorkplacesBaseNumber = (index) => workplacesFieldsNumber * index;
export const getConflictBaseNumber = (index) => conflictOfInterestFieldsNumber * index;

export const sections = {
  agreement: 'agreement',
  personalInformation: 'personalInformation',
  education: 'education',
  workplaces: 'workplaces',
  conflictOfInterest: 'conflictOfInterest',
  submittingData: 'submittingData',
  legal: 'legal',
  sanctionsList: 'sanctionsList',
  economy: 'economy',
  companies: 'companies',
  drivingLicense: 'drivingLicense',
  media: 'media',
  household: 'household',
  generalSummary: 'generalSummary',
};

export const formSections = [
  sections.personalInformation,
  sections.education,
  sections.workplaces,
  sections.conflictOfInterest,
];

export const viewSections = [
  sections.personalInformation,
  sections.education,
  sections.workplaces,
  sections.conflictOfInterest,
  sections.legal,
  sections.sanctionsList,
  sections.economy,
  sections.companies,
  sections.drivingLicense,
  sections.media,
  sections.household,
];

export const viewEvaluationSummarySections = [
  sections.personalInformation,
  sections.education,
  sections.workplaces,
  sections.legal,
  sections.sanctionsList,
  sections.economy,
  sections.companies,
  sections.drivingLicense,
  sections.media,
  sections.household,
];

export const mapCandidateFormSections = (sectionsToDisplay) => {
  const mappedSections = formSections.filter((page) => sectionsToDisplay[page] && page);
  mappedSections.unshift(sections.agreement);
  mappedSections.push(sections.submittingData);
  dispatch(setCandidateSections(mappedSections));

  return mappedSections;
};

export const mapCandidateViewSections = (sectionsToDisplay) => {
  const mappedSections = viewSections.filter((page) => sectionsToDisplay?.[page] && page);
  mappedSections.push(sections.generalSummary);
  dispatch(setCandidateSections(mappedSections));
};

export const getSectionNumber = (name) => store.getState().candidateSections?.indexOf(name);

export const getPersonalInfoSectionNum = () => getSectionNumber(sections.personalInformation);
export const getEducationSectionNum = () => getSectionNumber(sections.education);
export const getWorkplacesSectionNum = () => getSectionNumber(sections.workplaces);
export const getConflictSectionNum = () => getSectionNumber(sections.conflictOfInterest);
export const getLegalSectionNum = () => getSectionNumber(sections.legal);
export const getSanctionsListSectionNum = () => getSectionNumber(sections.sanctionsList);
export const getEconomySectionNum = () => getSectionNumber(sections.economy);
export const getCompaniesSectionNum = () => getSectionNumber(sections.companies);
export const getDrivingSectionNum = () => getSectionNumber(sections.drivingLicense);
export const getMediaSectionNum = () => getSectionNumber(sections.media);
export const getMemberOfHouseholdsSectionNum = () => getSectionNumber(sections.household);
export const getGeneralSummarySectionNum = () => getSectionNumber(sections.generalSummary);

export const shouldDisplaySection = (name) => (getSectionNumber(name) === -1 ? false : true);
