import { useEffect, useRef, useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { sections, shouldDisplaySection } from 'utils/candidateFormSections';
import { dateToStringYearMonthDayHoursMin } from 'utils/dateUtils';
import { downloadFile } from 'utils/downloadFile';
import { updateEvaluation, editPersonalInformation } from 'api/candidate';
import useDispatchNotification from 'components/Shared/Notification/DispatchNotificationHook';
import FloatingMessageAccordion from 'components/Messages/FloatingMessageAccordion';
import FloatingMessageAsGP from 'components/Messages/FloatingMessageAsGP';
import { Button, SelectedFile, DetailRow, Div, Paragraph } from 'components/Shared/sharedStyle';
import { personalDetailsBodyMapper } from 'components/CandidateForm/Form/configCandidateForm';
import RouteLeaveGuard from 'components/Shared/Modal/RouteLeaveGuard';
import { personalDetailsSchema } from 'components/CandidateView/schemaCandidateView';
import PersonalDetails from 'components/CandidateView/sections/PersonalDetails/PersonalDetails';
import Education from 'components/CandidateView/sections/Education';
import Workplaces from 'components/CandidateView/sections/Workplaces';
import ConflictOfInterest from 'components/CandidateView/sections/ConflictOfInterest';
import Legal from 'components/CandidateView/sections/Legal';
import Economy from 'components/CandidateView/sections/Economy';
import Companies from 'components/CandidateView/sections/Companies';
import DrivingLicense from 'components/CandidateView/sections/DrivingLicense';
import Media from 'components/CandidateView/sections/Media';
import SanctionsList from 'components/CandidateView/sections/SanctionsList';
import GeneralSummary from 'components/CandidateView/sections/GeneralSummary';
import EvaluationSummary from 'components/CandidateView/EvaluationSummary';
import ReportActions from 'components/CandidateView/ReportActions';
import { CandidateViewContainer, FixedContainer } from 'components/CandidateView/styleCandidateView';
import MembersOfHouseholds from 'components/CandidateView/sections/MemberOfHouseholds';
import { TitleButton } from 'components/Messages/styleMessages';
import { getEvaluationContract } from 'components/CandidateView/configCandidateView';
import { LogoutContext } from 'contexts/LogoutContext';

const CandidateViewForm = ({ candidateDetails, evaluation, setEvaluation }) => {
  const { dispatchSuccessNotification, dispatchErrorNotification } = useDispatchNotification();
  const { t } = useTranslation();
  const history = useHistory();
  const { id: candidateId } = useParams();
  const { setLogoutCallback } = useContext(LogoutContext);

  const isMobile = useSelector((state) => state.isMobile);

  const textBoxesRefs = useRef({});

  const formFunctions = useForm({
    validationSchema: personalDetailsSchema,
    validationContext: { requiredYears: candidateDetails?.requiredNumberOfYears?.personalInformation },
  });

  const { getValues } = formFunctions;

  const lastSavedPersonalDetails = useRef(JSON.stringify(getValues()));

  const setLastSavedPersonalDetails = useCallback(
    () =>
      setTimeout(() => {
        lastSavedPersonalDetails.current = JSON.stringify(getValues());
      }, 0),
    [getValues]
  );

  const lastSavedEvaluationContract = useRef(
    setTimeout(() => {
      lastSavedEvaluationContract.current = JSON.stringify(getEvaluationContract(evaluation, textBoxesRefs));
    }, 0)
  );

  const setLastSavedEvaluationContract = useCallback(
    (passedEvaluation) =>
      setTimeout(() => {
        lastSavedEvaluationContract.current = JSON.stringify(getEvaluationContract(passedEvaluation, textBoxesRefs));
      }, 0),
    []
  );

  const findUnsavedFormChanges = useCallback(() => {
    return (
      lastSavedPersonalDetails.current !== JSON.stringify(getValues()) ||
      lastSavedEvaluationContract.current !== JSON.stringify(getEvaluationContract(evaluation, textBoxesRefs))
    );
  }, [evaluation, getValues]);

  const updateEvaluationStatus = (key, status) => {
    setEvaluation((state) => ({
      ...state,
      [key]: status,
    }));
  };

  const sendReportToBe = useCallback(async () => {
    const mappedPersonalDetails = personalDetailsBodyMapper(getValues({ nest: true }));
    const sendPersonalInfo = editPersonalInformation(candidateId, mappedPersonalDetails);

    const sendUpdateEvaluation = updateEvaluation({
      id: candidateId,
      evaluationContract: getEvaluationContract(evaluation, textBoxesRefs),
    });

    await Promise.all([sendPersonalInfo, sendUpdateEvaluation]);
  }, [candidateId, evaluation, getValues]);

  const saveReport = useCallback(async () => {
    try {
      await sendReportToBe();

      setLastSavedPersonalDetails();
      setLastSavedEvaluationContract(evaluation);
      dispatchSuccessNotification(t('notifications.saved'));
    } catch (catchedError) {
      dispatchErrorNotification({ catchedError });
    }
  }, [
    dispatchErrorNotification,
    dispatchSuccessNotification,
    evaluation,
    sendReportToBe,
    setLastSavedEvaluationContract,
    setLastSavedPersonalDetails,
    t,
  ]);

  useEffect(() => {
    const saveFormBeforeLogout = async () => {
      if (findUnsavedFormChanges()) {
        try {
          await sendReportToBe();
        } catch (catchedError) {
          dispatchErrorNotification({ catchedError });
        }
      }
    };

    setLogoutCallback(() => saveFormBeforeLogout);

    return () => {
      setLogoutCallback(null);
    };
  }, [findUnsavedFormChanges, setLogoutCallback, sendReportToBe, dispatchErrorNotification]);

  return (
    <CandidateViewContainer>
      <div>
        <h2>{t('candidateView.title')}</h2>
        <p>{t('candidateView.description')}</p>
        <EvaluationSummary evaluation={evaluation} />
        <Div margin="20px 0px">
          <DetailRow>
            <span>{t('candidateView.candidatesCustomer')}</span>
            {candidateDetails?.candidate?.customer}
          </DetailRow>
          <DetailRow>
            <span>{t('candidateView.initiateDate')}</span>
            {dateToStringYearMonthDayHoursMin(candidateDetails?.candidate?.startDate)}
          </DetailRow>
          <DetailRow>
            <span>{t('candidateView.candidateConsent')}</span>
            {candidateDetails?.files?.ConsentForm
              ? candidateDetails.files.ConsentForm.map((file) => (
                  <SelectedFile
                    onClick={() => downloadFile({ id: file.id, fileName: file.name })}
                    download
                    key={file.name}
                  >
                    {file.name}
                  </SelectedFile>
                ))
              : t('candidateView.notAvailableYet')}
          </DetailRow>
          <DetailRow>
            <span>{t('candidateView.consentSubmittedDate')}</span>
            {candidateDetails?.candidate?.submittedDate
              ? dateToStringYearMonthDayHoursMin(candidateDetails.candidate.submittedDate)
              : t('candidateView.notAvailableYet')}
          </DetailRow>
        </Div>
        {shouldDisplaySection(sections.personalInformation) && (
          <PersonalDetails
            personalInformation={candidateDetails.personalInformation}
            editedPersonalInformation={candidateDetails.editedPersonalInformation}
            requiredNumberOfYears={candidateDetails.requiredNumberOfYears}
            formFunctions={formFunctions}
            setLastSavedPersonalDetails={setLastSavedPersonalDetails}
            files={candidateDetails.files}
            textBoxesRefs={textBoxesRefs}
            defaultEvaluation={candidateDetails.evaluation.personalInformation}
            evaluation={evaluation?.personalInformation}
            updateEvaluationStatus={updateEvaluationStatus}
          />
        )}
        {shouldDisplaySection(sections.education) && (
          <Education
            education={candidateDetails.education}
            noEducation={candidateDetails.personalInformation.noEducation}
            files={candidateDetails.files}
            textBoxesRefs={textBoxesRefs}
            defaultEvaluation={candidateDetails.evaluation.education}
            evaluation={evaluation?.education}
            updateEvaluationStatus={updateEvaluationStatus}
          />
        )}
        {shouldDisplaySection(sections.workplaces) && (
          <Workplaces
            workplaces={candidateDetails.workplaces}
            noWorkplace={candidateDetails.personalInformation.noWorkplace}
            requiredNumberOfYears={candidateDetails.requiredNumberOfYears}
            files={candidateDetails.files}
            textBoxesRefs={textBoxesRefs}
            defaultEvaluation={candidateDetails.evaluation.workplace}
            evaluation={evaluation?.workplaces}
            updateEvaluationStatus={updateEvaluationStatus}
          />
        )}
        {shouldDisplaySection(sections.conflictOfInterest) && (
          <ConflictOfInterest conflictOfInterest={candidateDetails.conflictOfInterest} textBoxesRefs={textBoxesRefs} />
        )}
        {shouldDisplaySection(sections.legal) && (
          <Legal
            textBoxesRefs={textBoxesRefs}
            defaultEvaluation={candidateDetails.evaluation.legal}
            evaluation={evaluation?.legal}
            countryOfIdNumber={candidateDetails.personalInformation.countryOfIdNumber}
            updateEvaluationStatus={updateEvaluationStatus}
          />
        )}
        {shouldDisplaySection(sections.sanctionsList) && (
          <SanctionsList
            textBoxesRefs={textBoxesRefs}
            evaluation={evaluation?.sanctionsList}
            defaultEvaluation={candidateDetails.evaluation.sanctionsList}
            updateEvaluationStatus={updateEvaluationStatus}
          />
        )}
        {shouldDisplaySection(sections.economy) && (
          <Economy
            textBoxesRefs={textBoxesRefs}
            defaultEvaluation={candidateDetails.evaluation.economy}
            evaluation={evaluation?.economy}
            bisnodeData={candidateDetails.bisnodeData}
            updateEvaluationStatus={updateEvaluationStatus}
          />
        )}
        {shouldDisplaySection(sections.companies) && (
          <Companies
            textBoxesRefs={textBoxesRefs}
            defaultEvaluation={candidateDetails.evaluation.companies}
            evaluation={evaluation?.companies}
            updateEvaluationStatus={updateEvaluationStatus}
          />
        )}
        {shouldDisplaySection(sections.drivingLicense) && (
          <DrivingLicense
            textBoxesRefs={textBoxesRefs}
            defaultEvaluation={candidateDetails.evaluation.drivingLicenseEvaluation}
            evaluation={evaluation?.drivingLicense}
            updateEvaluationStatus={updateEvaluationStatus}
          />
        )}
        {shouldDisplaySection(sections.media) && (
          <Media
            textBoxesRefs={textBoxesRefs}
            defaultEvaluation={candidateDetails.evaluation?.media}
            evaluation={evaluation?.media}
            updateEvaluationStatus={updateEvaluationStatus}
          />
        )}
        {shouldDisplaySection(sections.household) && (
          <MembersOfHouseholds
            textBoxesRefs={textBoxesRefs}
            defaultEvaluation={candidateDetails.evaluation.household}
            evaluation={evaluation?.household}
            updateEvaluationStatus={updateEvaluationStatus}
          />
        )}
        {shouldDisplaySection(sections.generalSummary) && (
          <GeneralSummary textBoxesRefs={textBoxesRefs} evaluation={candidateDetails.evaluation.generalSummary} />
        )}
        <ReportActions
          onFormSubmit={saveReport}
          reportFile={candidateDetails?.files?.Report}
          consentForm={candidateDetails?.files?.ConsentForm}
        />
      </div>
      <div>
        <FixedContainer>
          {!isMobile && (
            <>
              <FloatingMessageAccordion
                render={(setHeightOnChange, animateTransitionRef) => (
                  <FloatingMessageAsGP
                    candidate={candidateDetails?.candidate}
                    setHeightOnChange={setHeightOnChange}
                    animateTransitionRef={animateTransitionRef}
                  />
                )}
              />
              <TitleButton
                onClick={() =>
                  history.push(
                    `/messages-history/${candidateId}/${candidateDetails.candidate.name}/${candidateDetails.candidate.surname}`
                  )
                }
                marginTop="20px"
              >
                <Paragraph uppercase>{t('candidateView.messagesHistory')}</Paragraph>
              </TitleButton>
            </>
          )}
          <Button onClick={saveReport} marginLeft={isMobile && '20px'} marginTop="20px">
            {t('common.save')}
          </Button>
        </FixedContainer>
      </div>
      <RouteLeaveGuard
        when={true}
        shouldBlockNavigation={findUnsavedFormChanges}
        onConfirmClick={saveReport}
        navigateOnDecline
      />
    </CandidateViewContainer>
  );
};

CandidateViewForm.propTypes = {
  candidateDetails: PropTypes.object,
  evaluation: PropTypes.object,
  setEvaluation: PropTypes.func,
};

export default CandidateViewForm;
