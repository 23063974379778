import React, { useState, useEffect, useCallback } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { SignalRProvider } from 'contexts/SignalRContext';
import Login from 'components/Login/Login';
import ProtectedRoute from 'components/Shared/ProtectedRoute';
import SignedInContent from 'components/SignedInContent';
import SetPasswordPage from 'components/Login/SetPasswordPage';
import { getSignedIn } from 'utils/localStorage';
import { useGotoLogoutPage } from 'hooks/useLogout';
import { useSelector } from 'react-redux';
import { Inspector } from 'react-dev-inspector';
import { Loader } from 'components/Shared/Loader/Loaders';
import { LoaderFullScreen } from 'components/Shared/Loader/LoaderFullScreen';
import { refreshTokenFunc } from 'utils/authService';
import { LogoutContextProvider } from 'contexts/LogoutContext';

// use shortcut form InspectorWrapper to inspect element in browser and jump directly to code in IDE
const InspectorWrapper = process.env.NODE_ENV === 'development' ? Inspector : React.Fragment;

const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });
  const gotoLogoutPage = useGotoLogoutPage();

  const isSignedIn = getSignedIn();
  const isRedirectedFromLoginPage = useSelector((state) => state.isRedirectedFromLoginPage); // ensures correct logout by forcing rerender
  const [isInitializing, setInitialization] = useState(true);

  const refreshOnReload = useCallback(async () => {
    if (!isSignedIn) {
      setInitialization(false);
      return;
    }

    setInitialization(true);

    // prevents refreshing tokens on login
    if (!isRedirectedFromLoginPage) {
      try {
        await refreshTokenFunc();
      } catch (err) {
        gotoLogoutPage();
      }
    }

    setInitialization(false);
  }, [gotoLogoutPage, isSignedIn, isRedirectedFromLoginPage]);

  useEffect(() => {
    refreshOnReload();
  }, [refreshOnReload]);

  if (isInitializing) return <Loader />;

  queryClient.clear(); // clears react-query cache on login, logout and reload

  return (
    <InspectorWrapper
      keys={['control', 'alt', 'c']}
      onClickElement={({ codeInfo }) => {
        if (!codeInfo?.absolutePath) return;
        const { absolutePath, lineNumber, columnNumber } = codeInfo;
        window.open(`vscode://file/${absolutePath}:${lineNumber}:${columnNumber}`);
      }}
    >
      <QueryClientProvider client={queryClient}>
        <SignalRProvider>
          <LogoutContextProvider>
            <Switch>
              <Route path="/login/:automaticLogout?" render={() => (isSignedIn ? <Redirect to="/" /> : <Login />)} />
              <Route
                path="/set-password/:token/:email"
                render={(props) =>
                  isSignedIn ? <Redirect to="/" /> : <SetPasswordPage {...props} isNewCandidate={true} />
                }
              />
              <Route
                path="/reset-password/:token/:email"
                render={(props) => (isSignedIn ? <Redirect to="/" /> : <SetPasswordPage {...props} />)}
              />
              <ProtectedRoute path="/" component={SignedInContent} />
            </Switch>
            <LoaderFullScreen />
          </LogoutContextProvider>
        </SignalRProvider>
        {/* Devtools can drastically slowdown performance when opened and there are many cached request - can even render longer than app itself - use only if needed */}
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </QueryClientProvider>
    </InspectorWrapper>
  );
};

export default App;
