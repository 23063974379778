import React, { useEffect, useState, useCallback, useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';

import { mapCandidateViewSections } from 'utils/candidateFormSections';
import { setCountriesAdditionalFields } from 'store/actions';
import useDispatchNotification from 'components/Shared/Notification/DispatchNotificationHook';
import { LoaderInsideElements } from 'components/Shared/Loader/Loaders';
import CandidateViewForm from 'components/CandidateView/CandidateViewForm';
import { useGetVerifieraData } from 'hooks/api/candidate/useGetVerifieraData';
import { useGetAllFields } from 'hooks/api/candidate/useGetAllFields';
import { useGetCandidateData } from 'hooks/api/candidate/useGetCandidateData';
import { NoDataMessage } from 'components/Shared/Grid/styleGrid';
import { useTranslation } from 'react-i18next';

const CandidateView = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { dispatchErrorNotification } = useDispatchNotification();

  const [candidateDetails, setCandidateDetails] = useState(null);
  const [evaluation, setEvaluation] = useState(null);

  const { isLoading: isLoadingVerifieraData } = useGetVerifieraData();
  const { data: candidateData, isLoading: isLoadingCandidateData, isError } = useGetCandidateData();
  const { data: countriesAdditionalFields, isLoading: isLoadingCountriesAdditionalFields } = useGetAllFields();

  // TODO: Remove countriesAdditionalFields from redux and pull it from react-query.
  useEffect(() => {
    countriesAdditionalFields && dispatch(setCountriesAdditionalFields(countriesAdditionalFields));
  }, [countriesAdditionalFields, dispatch]);

  // TODO: Refactor all child components to get candidateData from react-query.
  const parseCandidateData = useCallback(() => {
    try {
      mapCandidateViewSections(candidateData?.sectionsToDisplay);

      setCandidateDetails({
        candidate: candidateData?.candidate || {},
        files: candidateData?.files || {},
        evaluation: candidateData?.evaluation || {},
        personalInformation: candidateData?.personalInformation || {},
        editedPersonalInformation: candidateData?.editedPersonalInformation || {},
        requiredNumberOfYears: candidateData?.requiredNumberOfYears || {},
        education: candidateData?.education || {},
        workplaces: candidateData?.workplaces || {},
        conflictOfInterest: candidateData?.conflictOfInterest || {},
        legal: candidateData?.legal || {},
        bisnodeData: candidateData?.bisnodeData || {},
      });

      const { evaluation } = candidateData;

      if (evaluation) {
        const evaluationObj = {
          personalInformation: evaluation?.personalInformation?.evaluation,
          education: evaluation?.education?.evaluation,
          workplaces: evaluation?.workplace?.evaluation,
          legal: evaluation?.legal?.evaluation,
          sanctionsList: evaluation?.sanctionsList?.evaluation,
          economy: evaluation?.economy?.evaluation,
          companies: evaluation?.companies?.evaluation,
          drivingLicense: evaluation?.drivingLicenseEvaluation?.evaluation,
          media: evaluation?.media?.evaluation,
          household: evaluation?.household?.evaluation,
        };

        setEvaluation(evaluationObj);
      }
    } catch (catchedError) {
      dispatchErrorNotification({ catchedError });
    }
  }, [candidateData, dispatchErrorNotification]);

  useLayoutEffect(() => {
    candidateData && parseCandidateData();
  }, [candidateData, parseCandidateData]);

  if (isLoadingCandidateData || isLoadingVerifieraData || isLoadingCountriesAdditionalFields)
    return <LoaderInsideElements />;

  if (!candidateData || isError) return <NoDataMessage>{t('common.noData')}</NoDataMessage>;

  if (!candidateDetails) return <LoaderInsideElements />;

  return (
    <CandidateViewForm
      candidateDetails={candidateDetails}
      setCandidateDetails={setCandidateDetails}
      evaluation={evaluation}
      setEvaluation={setEvaluation}
    />
  );
};

export default CandidateView;
