import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { getVerifiera } from 'api/candidate';

export function useGetVerifieraData() {
  const { id: candidateId } = useParams();

  return useQuery({
    queryKey: ['getVerifieraData', candidateId],
    queryFn: async () => {
      return await getVerifiera(candidateId, { maxItems: 5 });
    },
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });
}
