import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import hasPermission from 'utils/hasPermission';
import roles from 'constants/roles';
import { registerUser, getCustomersList, registerCustomerOwnUser } from 'api/customer';
import { Divider, Button, FormRow, FormGroup, Container, Form, Flexbox } from 'components/Shared/sharedStyle';
import { Input, Select } from 'components/Shared/sharedComponents';
import { customerUserSchema, customerUserSchemaAsGp } from 'components/Customers/customerSchema';
import { mapCustomersOptions } from 'components/Customers/configCustomer';
import useDispatchNotification from 'components/Shared/Notification/DispatchNotificationHook';

const AddCustomerUser = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { dispatchSuccessNotification, dispatchErrorNotification } = useDispatchNotification();

  const [isLoading, setLoadingStatus] = useState('');
  const [customersOptions, setCustomersOptions] = useState([]);
  const [shouldReset, setShouldResetStatus] = useState(false);

  const hasGpPermissions = hasPermission([roles.investigator]);

  const formFunctions = useForm({
    validationSchema: hasGpPermissions ? customerUserSchemaAsGp : customerUserSchema,
  });

  const { register, handleSubmit, errors, reset } = formFunctions;

  useEffect(() => {
    if (hasGpPermissions) {
      (async () => {
        const data = await getCustomersList();
        setCustomersOptions(mapCustomersOptions(data));
      })();
    }
  }, [hasGpPermissions]);

  const roleOptions = [
    { value: 'supervisor', label: t('customer.supervisor') },
    { value: 'regular', label: t('customer.regular') },
  ];

  const addNewCustomerUser = async (form) => {
    setLoadingStatus(true);
    const formBody = {
      firstName: form.firstName,
      lastName: form.lastName,
      email: form.email,
      isSupervisor: form.role === 'supervisor',
      locale: 'en',
    };

    try {
      if (hasGpPermissions) {
        await registerUser({
          ...formBody,
          customerId: form.companyName,
        });

        history.push('/customer-users');
      } else {
        await registerCustomerOwnUser(formBody);
        reset();
        setShouldResetStatus(true);
        setShouldResetStatus(false);
      }

      dispatchSuccessNotification(t('notifications.saved'));
    } catch (catchedError) {
      dispatchErrorNotification({ catchedError });
      setLoadingStatus(false);
    }
  };

  return (
    <Container>
      <h2>{hasGpPermissions ? t('customer.addCustomerUser') : t('customer.addUser')}</h2>
      <Divider />
      <Form onSubmit={handleSubmit(addNewCustomerUser)}>
        <FormRow>
          <FormGroup>
            <Input
              label={t('customer.labels.firstName')}
              name="firstName"
              error={errors.firstName}
              register={register}
            />
          </FormGroup>
          <FormGroup>
            <Input label={t('customer.labels.lastName')} name="lastName" error={errors.lastName} register={register} />
          </FormGroup>
        </FormRow>
        <FormRow>
          <FormGroup>
            <Input label={t('customer.labels.email')} name="email" error={errors.email} register={register} />
          </FormGroup>
          <FormGroup>
            <Select
              name="role"
              error={errors.role}
              selectFunctions={formFunctions}
              label={t('customer.labels.role')}
              options={roleOptions}
              resetAfterSubmit={shouldReset}
            />
          </FormGroup>
        </FormRow>
        {hasGpPermissions && (
          <FormRow>
            <FormGroup>
              <Select
                name="companyName"
                error={errors.companyName}
                selectFunctions={formFunctions}
                label={t('customer.labels.companyName')}
                options={customersOptions}
              />
            </FormGroup>
            <FormGroup />
          </FormRow>
        )}
        <Flexbox justify="flex-end">
          <Button disabled={isLoading}>{t('common.save')}</Button>
        </Flexbox>
      </Form>
    </Container>
  );
};

export default AddCustomerUser;
