import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';

import colors from 'constants/colors';
import icons from 'constants/icons';
import { FormRow, FormGroup, IconButton, Flexbox, Paragraph } from 'components/Shared/sharedStyle';
import { Input, DatePicker, Select, FormCheckbox, MultipleFilesUploader } from 'components/Shared/sharedComponents';
import { getWorkplacesSectionNum, getWorkplacesBaseNumber } from 'utils/candidateFormSections';
import { yearMonthDatePickerFormat } from 'utils/dateUtils';
import { SectionName, FormGroupWithCheckbox } from 'components/CandidateForm/styleCandidateForm';
import { getEmplymentOptions } from 'constants/emplymentTypes';
import { workplacesType } from 'constants/types';

const WorkplaceForm = ({
  formFunctions,
  remove,
  groupId,
  index,
  editingLocked,
  defaultFiles,
  payslipsToRemoveFromView,
  defaultFormState,
}) => {
  const { t } = useTranslation();
  const sectionNumber = getWorkplacesSectionNum();
  const baseNumber = getWorkplacesBaseNumber(index);

  const [shouldReset, setShouldResetStatus] = useState(false);

  const { register, errors, triggerValidation, formState, watch, clearError, setValue } = formFunctions;

  const errorsReference = (field) => errors.workplace && errors.workplace[groupId] && errors.workplace[groupId][field];

  const startDateStateValue = watch(`workplace[${groupId}].startDate`);
  const endDateStateValue = watch(`workplace[${groupId}].endDate`);
  const currentEmployment = watch(`workplace[${groupId}].currentEmployment`);
  const noAdditionalEmploymentForRequestedTime = watch('noAdditionalEmploymentForRequestedTime');
  const yesContactWorkplace = watch(`workplace[${groupId}].yesContactWorkplace`);
  const dontContactWorkplace = watch(`workplace[${groupId}].dontContactWorkplace`);

  useEffect(() => {
    if (formState.isSubmitted) {
      triggerValidation('dateRangeError');
    }
  }, [
    startDateStateValue,
    endDateStateValue,
    triggerValidation,
    formState.isSubmitted,
    noAdditionalEmploymentForRequestedTime,
  ]);

  useEffect(() => {
    if (payslipsToRemoveFromView.find((id) => id === groupId)) {
      setShouldResetStatus(true);
    }
  }, [groupId, payslipsToRemoveFromView]);

  useEffect(() => {
    setShouldResetStatus(false);
  }, [shouldReset]);

  const [payslipFiles] = useMemo(() => {
    // you have to use useMemo and null so it will not glith during first upload
    // if you pass directly to MultipleFilesUploader props, it will glith
    let payslipFiles = null;

    if (defaultFiles && defaultFiles.Payslip) {
      payslipFiles = defaultFiles?.Payslip?.filter((file) => file.group === index);
    }

    return [payslipFiles];
  }, [defaultFiles, index]);

  const commonTriggerValidations = () => {
    triggerValidation(`workplace[${groupId}].canWeContactYourCurrentWorkplace`);
    triggerValidation(`workplace[${groupId}].companyPhoneNumberSwitchboard`);
    triggerValidation(`workplace[${groupId}].companyPhoneNumberHrDepartment`);
    triggerValidation(`workplace[${groupId}].supervisorName`);
    triggerValidation(`workplace[${groupId}].supervisorPhone`);
    triggerValidation(`workplace[${groupId}].supervisorEmail`);
  };

  const onCurrentEmploymentChange = () => {
    if (formState.isSubmitted) {
      clearError(`workplace[${groupId}].endDate`);
      triggerValidation(`workplace[${groupId}].endDate`);
      triggerValidation(`dateRangeError`);

      commonTriggerValidations();
    }
  };

  const onYesContactWorkplaceChange = () => {
    setValue(`workplace[${groupId}].dontContactWorkplace`, false);
    if (formState.isSubmitted) {
      commonTriggerValidations();
    }
  };

  const onDontContactWorkplaceChange = () => {
    setValue(`workplace[${groupId}].yesContactWorkplace`, false);
    if (formState.isSubmitted) {
      commonTriggerValidations();
    }
  };

  const disableContactFields = currentEmployment && !yesContactWorkplace;

  return (
    <>
      <SectionName>
        <h3>{t('candidateForm.workplace.sectionName', { index: index + 1 })}</h3>
        {remove && <IconButton onClick={() => remove(groupId)} icon={icons.delete_bin} />}
      </SectionName>
      <FormRow>
        <FormGroup>
          <Input
            register={register}
            name={`workplace[${groupId}].companyName`}
            error={errorsReference('companyName')}
            label={`${sectionNumber}.${baseNumber + 1} ${t('candidateForm.labels.companyName')}`}
          />
        </FormGroup>
        <FormGroup>
          <Input
            register={register}
            name={`workplace[${groupId}].jobPosition`}
            error={errorsReference('jobPosition')}
            label={`${sectionNumber}.${baseNumber + 2} ${t('candidateForm.labels.jobPosition')}`}
          />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Input
            register={register}
            name={`workplace[${groupId}].companyAddress`}
            error={errorsReference('companyAddress')}
            label={`${sectionNumber}.${baseNumber + 3} ${t('candidateForm.labels.companyAddress')}`}
          />
        </FormGroup>
        <FormGroup>
          <Input
            register={register}
            name={`workplace[${groupId}].placeOfWork`}
            error={errorsReference('placeOfWork')}
            label={`${sectionNumber}.${baseNumber + 4} ${t('candidateForm.labels.placeOfWork')}`}
          />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Select
            name={`workplace[${groupId}].employmentType`}
            error={errorsReference('employmentType')}
            selectFunctions={formFunctions}
            label={`${sectionNumber}.${baseNumber + 5} ${t('candidateForm.labels.employmentType')}`}
            options={getEmplymentOptions(t)}
            defaultValue={
              defaultFormState &&
              getEmplymentOptions(t).find((option) => option?.value === defaultFormState[index]?.employmentType)
            }
            disabled={editingLocked}
          />
        </FormGroup>
        <FormGroup>
          <Input
            register={register}
            name={`workplace[${groupId}].comments`}
            error={errorsReference('comments')}
            label={`${sectionNumber}.${baseNumber + 6} ${t('candidateForm.labels.comments')}`}
          />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <DatePicker
            name={`workplace[${groupId}].startDate`}
            error={errorsReference('startDate')}
            label={`${sectionNumber}.${baseNumber + 7} ${t('candidateForm.labels.startDate')}`}
            dateFormat={yearMonthDatePickerFormat}
            showMonthYearPicker
            disabled={editingLocked}
            formFunctions={formFunctions}
            onChange={([date]) => date}
            scrollableYearDropdown={true}
            maxDate={endDateStateValue}
            isClearable
          />
        </FormGroup>
        <FormGroupWithCheckbox>
          <DatePicker
            name={`workplace[${groupId}].endDate`}
            error={errorsReference('endDate')}
            label={`${sectionNumber}.${baseNumber + 8} ${t('candidateForm.labels.endDate')}`}
            dateFormat={yearMonthDatePickerFormat}
            showMonthYearPicker
            disabled={editingLocked || currentEmployment}
            formFunctions={formFunctions}
            onChange={([date]) => date}
            scrollableYearDropdown
            maxDate={new Date()}
            minDate={startDateStateValue}
            isClearable
          />
          <FormCheckbox
            label={t('candidateForm.labels.currentEmployment')}
            name={`workplace[${groupId}].currentEmployment`}
            register={register}
            error={errorsReference('currentEmployment')}
            marginTop="15px"
            width="50%"
            onChange={onCurrentEmploymentChange}
          />
        </FormGroupWithCheckbox>
      </FormRow>
      <Flexbox direction="column" display={!currentEmployment ? 'none' : ''}>
        <Paragraph marginBottom="0px">{t('candidateForm.labels.canWeContactYourWorkplace')}</Paragraph>
        <FormCheckbox
          label={<Trans i18nKey={'candidateForm.labels.yesContactWorkplace'} />}
          name={`workplace[${groupId}].yesContactWorkplace`}
          register={register}
          error={errorsReference('yesContactWorkplace')}
          onChange={onYesContactWorkplaceChange}
        />
        <Flexbox direction="row" gap="20px" position="relative" marginBottom="10px">
          <FormCheckbox
            label={<Trans i18nKey={'candidateForm.labels.dontContactWorkplace'} />}
            name={`workplace[${groupId}].dontContactWorkplace`}
            register={register}
            error={errorsReference('dontContactWorkplace')}
            onChange={onDontContactWorkplaceChange}
          />
          <FormGroup>
            <MultipleFilesUploader
              label={t('candidateForm.labels.attachPayslips')}
              name={`workplace[${groupId}].payslips`}
              errors={errorsReference('payslips')}
              formFunctions={formFunctions}
              defaultFiles={payslipFiles}
              disabled={editingLocked || !dontContactWorkplace}
              shouldReset={shouldReset}
              fileGroup={index}
            />
          </FormGroup>
        </Flexbox>
        {errorsReference('canWeContactYourCurrentWorkplace') && (
          <Paragraph position="absolute" marginTop="126px" color={colors.error}>
            {errorsReference('canWeContactYourCurrentWorkplace').message}
          </Paragraph>
        )}
      </Flexbox>
      <FormRow>
        <FormGroup>
          <Input
            register={register}
            name={`workplace[${groupId}].companyPhoneNumberSwitchboard`}
            error={errorsReference('companyPhoneNumberSwitchboard')}
            label={`${sectionNumber}.${baseNumber + 9} ${t('candidateForm.labels.companyPhoneNumberSwitchboard')}`}
            disabled={disableContactFields}
          />
        </FormGroup>
        <FormGroup>
          <Input
            register={register}
            name={`workplace[${groupId}].companyPhoneNumberHrDepartment`}
            error={errorsReference('companyPhoneNumberHrDepartment')}
            label={`${sectionNumber}.${baseNumber + 10} ${t('candidateForm.labels.companyPhoneNumberHrDepartment')}`}
            disabled={disableContactFields}
          />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Input
            register={register}
            name={`workplace[${groupId}].supervisorName`}
            error={errorsReference('supervisorName')}
            label={`${sectionNumber}.${baseNumber + 11} ${t('candidateForm.labels.supervisorName')}`}
            disabled={disableContactFields}
          />
        </FormGroup>
        <FormGroup>
          <Input
            register={register}
            name={`workplace[${groupId}].supervisorPhone`}
            error={errorsReference('supervisorPhone')}
            label={`${sectionNumber}.${baseNumber + 12} ${t('candidateForm.labels.supervisorPhone')}`}
            disabled={disableContactFields}
          />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Input
            register={register}
            name={`workplace[${groupId}].supervisorEmail`}
            error={errorsReference('supervisorEmail')}
            label={`${sectionNumber}.${baseNumber + 13} ${t('candidateForm.labels.supervisorEmail')}`}
            disabled={disableContactFields}
          />
        </FormGroup>
        <FormGroup></FormGroup>
      </FormRow>
    </>
  );
};

WorkplaceForm.propTypes = {
  formFunctions: PropTypes.object.isRequired,
  groupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  index: PropTypes.number.isRequired,
  editingLocked: PropTypes.bool.isRequired,
  remove: PropTypes.func,
  defaultFiles: PropTypes.object,
  defaultFormState: workplacesType,
  payslipsToRemoveFromView: PropTypes.arrayOf(PropTypes.string).isRequired,
};

WorkplaceForm.defaultProps = {
  remove: null,
};

export default WorkplaceForm;
