import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { markActivityAsViewed } from 'store/actions';
import useOnClickOutside from 'hooks/useOnClickOutsideHook';
import icons from 'constants/icons';
import { IconButton, Flexbox } from 'components/Shared/sharedStyle';
import { EventsCellContainer } from 'components/CandidatesList/styleCandidatesList';
import EventsModal from 'components/CandidatesList/EventsCell/EventsModal';

const EventsCell = ({ events, newActivity, newMessage, candidateName, status, candidateId }) => {
  const dispatch = useDispatch();
  const viewedActivities = useSelector((state) => state?.viewedActivities);

  const { ref, isVisible, toggleVisibility } = useOnClickOutside();

  if (!status) return null;

  const newFileInEvents = events.some(
    ({ activityType }) => activityType === 'FileUploaded' || activityType === 'DataSubmitted'
  );
  const newMessageInEvents = events.some(({ activityType }) => activityType === 'MessageSent');
  const activityAlreadyViewed = viewedActivities.includes(candidateId);

  const onModalOpen = () => {
    if (!activityAlreadyViewed) {
      dispatch(markActivityAsViewed(candidateId));
    }
  };

  return (
    <EventsCellContainer>
      <Flexbox justify="space-around" onClick={() => toggleVisibility(true)}>
        <IconButton
          icon={!activityAlreadyViewed && newMessageInEvents && newMessage ? icons.newMessageBlack : icons.messageBlack}
        />
        <IconButton
          icon={!activityAlreadyViewed && newFileInEvents && newActivity ? icons.newFileBlack : icons.fileBlack}
        />
      </Flexbox>
      {isVisible && (
        <div ref={ref}>
          <EventsModal
            onModalClose={() => toggleVisibility(false)}
            events={events}
            candidateName={candidateName}
            onModalOpen={onModalOpen}
          />
        </div>
      )}
    </EventsCellContainer>
  );
};

EventsCell.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      timeStamp: PropTypes.string.isRequired,
      activityType: PropTypes.string.isRequired,
    })
  ).isRequired,
  newActivity: PropTypes.bool.isRequired,
  newMessage: PropTypes.bool.isRequired,
  candidateName: PropTypes.string.isRequired,
  status: PropTypes.number.isRequired,
  candidateId: PropTypes.string.isRequired,
};

export default EventsCell;
