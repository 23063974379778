import { useQuery } from '@tanstack/react-query';
import { getAllFields } from 'api/country';

export function useGetAllFields() {
  return useQuery({
    queryKey: ['getAllFields'],
    queryFn: async () => {
      return await getAllFields({ includeEmpty: false });
    },
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });
}
